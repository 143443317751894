$bg: #222831;
$bg-light: #2d3541;
$bg-dim: #161a20;
$secondary-bg: #393E46;
$secondary-bg-light: #363b44;
$secondary-bg-dim: #393E46;
$main-text: #eee;
$main-text-light: #f5f5f5;
$main-text-dim: #ddd;
$main-text-dim-dim: rgb(184, 184, 184);
$secondary-light: #FFD369;
$secondary-dim: #ecc261;